import React from "react";
import PropTypes from 'prop-types';
import { map, addIndex } from 'ramda';
import dayjs from 'dayjs';
import advancedDayJsFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { 
  Wrapper, 
  List, 
  Item, 
  ItemTitle,
  ItemContent,
  ItemName, 
  ItemLink
} from './styles';
import { ConstructionUpdatesQuery } from 'graph/construction-updates';

// We need to parse dates returned by prismic as `2021-01-05T00:00:00+0000`
// TODO: We should move this into a utility if in future we need to parse dates in the same way elsewhere.
dayjs.extend(advancedDayJsFormat);
dayjs.extend(customParseFormat)

const ConstructionUpdateItem = ({ file: { url: fileUrl }, date, title }) => (
  <Item>
    <ItemTitle>{dayjs(date, 'YYYY-MM-DDTHH:mm:ss+0000').format('MMMM YYYY')}</ItemTitle>
    <ItemContent>
      <ItemName>{title}</ItemName>
      <ItemLink href={fileUrl} target="_blank">Read more...</ItemLink>
    </ItemContent>
  </Item>
);

ConstructionUpdateItem.propTypes = {
  file: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const ConstructionUpdatesList = () => {
  const updates = ConstructionUpdatesQuery();
  const mapWithIndex = addIndex(map);
  return (
    <Wrapper>
      <List>
        {mapWithIndex((update, index) => <ConstructionUpdateItem {...update} key={index} />, updates)}
      </List>
    </Wrapper>
  );
};

export default ConstructionUpdatesList;
