import React from 'react';
import { navigate } from 'gatsby';
import images from '../images';
import t from '../texts';
import {
  Wrapper,
  Content,
  Heading,
  StyledSlider as Slider,
  TextBg,
  CloseButton
} from './styles';
import ConstructionUpdatesList from 'components/ConstructionUpdatesList';

export default () => (
  <Wrapper>
    <CloseButton onClick={() => navigate('/')} />
    <Slider images={images} />
    <TextBg />
    <Content>
      <h6>{t.subheading}</h6>
      <Heading>{t.heading}</Heading>
      <ConstructionUpdatesList />
    </Content>
  </Wrapper>
)
