import { useStaticQuery, graphql } from 'gatsby';
import { map, sortBy, prop, reverse, pathOr } from 'ramda';

export const ConstructionUpdatesQuery = () => {
  const updates = useStaticQuery(graphql`
    query ConstructionUpdatesQuery {
      allPrismicConstructionUpdateNewsletter {
        edges {
          node {
            id
            data {
              newsletter_post_title {
                text
              }
              newsletter_post_date
              newsletter_pdf_file {
                name
                url
              }
            }
          }
        }
      }
    }
  `);
  const edges = pathOr([], [
    'allPrismicConstructionUpdateNewsletter', 
    'edges'
  ], updates); 
  return reverse(
    sortBy(
      prop('date'),
      map(({ node: { data: {
        newsletter_pdf_file: file,
        newsletter_post_date: date,
        newsletter_post_title: {
          text: title
        }
      } } }) => ({
        file,
        date,
        title
      }), edges)
    )
  );
};
