import styled from 'styled-components';
import { BigCloseButtonTopRight } from 'components/BigCloseButton';
import Slider from 'components/Slider';
import { media } from 'styles';
import { withPrefix } from 'gatsby';

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  padding-bottom: 55px;
`

export const CloseButton = styled(BigCloseButtonTopRight)`
  ${media.tablet`
    top: -34px;
  `}
`

export const LogoWrapper = styled.div`
  width: 100px;
  margin: 24px auto 37px;

  img {
    width: 100%;
    height: auto;
  }
`

export const Heading = styled.h2`
  margin: 25px 0;
`

export const StyledSlider = styled(Slider)`
  height: 60vw;
  min-height: 290px;
  margin-top: 40px;
`

export const Gallery = styled.div`
  margin-top: 73px;

  img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
  }
`

export const TextBg = styled.div`
  position: absolute;
  top: 70px;
  left: -90px;
  height: 700px;
  width: 250px;
  background: url(${withPrefix(`2020@2x.png`)}) center;
  background-size: 100%;
`
