import React from 'react';
import { Link, navigate } from 'gatsby';
import LogoImage from 'components/Images/LogoImage';
import images from '../images';
import t from '../texts';
import {
  Wrapper,
  TextBg,
  LogoWrapper,
  Heading,
  StyledSlider as Slider,
  CloseButton
} from './styles';
import ConstructionUpdatesList from 'components/ConstructionUpdatesList';

export default () => (
  <Wrapper>
    <CloseButton onClick={() => navigate('/')} />
    <TextBg />
    <LogoWrapper>
      <Link to="/">
        <LogoImage />
      </Link>
    </LogoWrapper>
    <h6>{t.subheading}</h6>
    <Heading>{t.heading}</Heading>
    <Slider images={images} />
    <ConstructionUpdatesList />
  </Wrapper>
)
