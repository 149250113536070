import { getTexts } from '../../utilities/translations';

// NOTE: This page doesn't have translations so we return the same value for texts
// in both `en` and `zh` properties of the module return value.
const textsWithNoTranslations = {
  subheading: `Brill Place Tower`,
  heading: `Brill Place Tower Construction Works Updates.`
};

export default getTexts({
  'en': { ...textsWithNoTranslations },
  'zh': { ...textsWithNoTranslations }
});
